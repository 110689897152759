import React, { Component } from 'react'
import { withStyles, createTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { Redirect } from 'react-router-dom'
import TableHead from '@material-ui/core/TableHead'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/AddBox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { green } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeProvider } from '@material-ui/styles'

const viewStyles = theme => ({
  root: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(5),
    overflowX: 'auto'
  },
  table: {
    flexGrow: 1
  },
  highlight: {
    color: 'white',
    paddingRight: 6,
    backgroundColor: green[300]
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  }
})

class Servers extends Component {
  state = {
    activeServer: {
      name: 'gameserver-01'
    },
    redirect: false,
    open: false,
    newServer: {
      name: '',
      hostName: '',
      user: '',
      type: '',
      port: '',
      keyFile: '',
      cloudProvider: 'gcp',
      instanceName: '',
      zone: '',
      gameType: ''
    }
  }

  viewServer = row => {
    this.setState({ activeServer: row, redirect: true })
  }

  handleClickOpen = type => {
    let state = Object.assign({}, this.state)
    state.newServer = {
      name: '',
      hostName: '',
      user: '',
      type: type,
      instanceName: '',
      zone: '',
      hasHercules: false
    }
    state.open = true
    this.setState(state)
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  convertStringToBool = stringValue => {
    return /true/i.test(stringValue.toLowerCase())
  }

  handleNewFormChange = (event, name) => {
    let state = Object.assign({}, this.state)

    let hasHercules = false
    if (name === 'hasHercules') {
      hasHercules = this.convertStringToBool(event.target.value)
      event.target.value = hasHercules
    }
    state.newServer[name] = event.target.value
    this.setState(state)
  }

  getActiveLinks = symlinks => {
    let children = []

    for (let link of symlinks) {
      children.push(
        <span>
          <span style={{ color: 'green' }}>{link.name}</span> -> {link.activeCommit.substr(0, 6)} <br />
        </span>
      )
    }

    return children
  }

  getActiveProcess = (type, port) => {
    switch (type) {
      case 'gameserver':
        if (port == '7082') return 'gameserver_2'
        else return 'gameserver_1'
      case 'router':
        if (port == '7080') return 'router_2'
        else return 'router_1'
      case 'userserver':
        if (port == '6081') return 'userserver_2'
        else return 'userserver_1'
      case 'consumer':
        return 'consumer_1'
      case 'statusserver':
        return 'statusserver_1'
      case 'statserver':
        return 'statserver_1'
      case 'clubserver':
        if (port == '7085') return 'clubserver_2'
        else return 'clubserver_1'
      case 'tesseract':
        if (port == '7089') return 'tesseract_2'
        else return 'tesseract_1'
    }
  }

  getTable = (servers, type) => {
    const { classes } = this.props
    servers.sort((a, b) => (a.name > b.name ? 1 : -1))
    return (
      <Paper className={classes.root}>
        <Toolbar className={classes.highlight}>
          <div className={classes.title}>
            <Typography
              variant='h6'
              id='tableTitle'
            >
              {type}
            </Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <Tooltip title='Add'>
              <IconButton
                onClick={() => this.handleClickOpen(type)}
                aria-label='add'
              >
                <AddIcon style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Server Name</TableCell>
              <TableCell align='right'>Active Links</TableCell>
              <TableCell align='right'>Version Code</TableCell>
              <TableCell align='right'>Active Port</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servers.map(row => (
              <TableRow
                hover
                style={{ display: 'table-row !important', width: '100%' }}
                onClick={() => this.props.viewServer(row)}
                key={row.name}
              >
                <TableCell
                  component='th'
                  scope='row'
                >
                  {row.name}
                </TableCell>
                <TableCell align='right'>{this.getActiveLinks(row.symlinks)}</TableCell>
                <TableCell align='right'>{row.versionCode}</TableCell>
                <TableCell align='right'>{row.port + ' (' + this.getActiveProcess(type, row.port) + ')'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }

  drawTables = () => {
    if (!this.props.servers) {
      return null
    }

    let servers = {}
    tables = []
    this.props.servers.sort((a, b) => (a.type > b.type ? 1 : -1))
    for (let server of this.props.serverTypes) {
      servers[server] = []
    }
    for (let server of this.props.servers) {
      if (servers[server.type]) {
        servers[server.type].push(server)
      } else {
        servers[server.type] = []
        servers[server.type].push(server)
      }
    }
    let tables = []
    for (let key in servers) {
      tables.push(this.getTable(servers[key], key))
    }
    console.log(servers)
    return tables
  }

  render() {
    const { classes } = this.props
    if (this.state.redirect) {
      return (
        <Redirect
          exact
          to={{
            pathname: 'server',
            state: { name: this.state.activeServer.name }
          }}
        />
      )
    }
    const theme = createTheme({
      palette: {
        primary: green
      }
    })

    return (
      <div>
        <main className={classes.content}>{this.drawTables()}</main>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          style={{ padding: 60 }}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>New Server</DialogTitle>
          <DialogContent style={{ paddingLeft: 40, paddingRight: 40 }}>
            <ThemeProvider theme={theme}>
              <FormControl
                style={{ marginBottom: 12 }}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor='gameType'>Game Type</InputLabel>
                <Select
                  value={this.state.newServer.gameType}
                  onChange={event => this.handleNewFormChange(event, 'gameType')}
                  inputProps={{
                    name: 'gameType',
                    id: 'server-gameType'
                  }}
                  fullWidth
                  menuStyle={{
                    position: 'absolute',
                    top: -12.5
                  }}
                  underlineStyle={{
                    position: 'relative',
                    top: 20
                  }}
                >
                  <MenuItem
                    key='ludo'
                    value='ludo'
                    selected='yes'
                  >
                    ludo
                  </MenuItem>
                  <MenuItem
                    key='parcheesi'
                    value='parcheesi'
                  >
                    parcheesi
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                style={{ marginBottom: 12 }}
                margin='dense'
                id='name'
                color='secondary'
                value={this.state.newServer.name}
                onChange={event => this.handleNewFormChange(event, 'name')}
                label='Server Name'
                type='text'
                fullWidth
              />
              <TextField
                style={{ marginBottom: 12 }}
                margin='dense'
                value={this.state.newServer.hostName}
                onChange={event => this.handleNewFormChange(event, 'hostName')}
                id='hostName'
                label='Host Private IP'
                type='text'
                fullWidth
              />
              <TextField
                style={{ marginBottom: 12 }}
                margin='dense'
                value={this.state.newServer.hostNamePublic}
                onChange={event => this.handleNewFormChange(event, 'hostNamePublic')}
                id='hostNamePublic'
                label='Host Public IP'
                type='text'
                fullWidth
              />
              <TextField
                style={{ marginBottom: 12 }}
                margin='dense'
                value={this.state.newServer.user}
                onChange={event => this.handleNewFormChange(event, 'user')}
                id='user'
                label='Username'
                type='text'
                fullWidth
              />
              <TextField
                style={{ marginBottom: 12 }}
                margin='dense'
                value={this.state.newServer.port}
                onChange={event => this.handleNewFormChange(event, 'port')}
                id='port'
                label='Port'
                type='text'
                fullWidth
              />
              <FormControl
                style={{ marginBottom: 12 }}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor='cloudProvider'>Cloud Provider</InputLabel>
                <Select
                  value={this.state.newServer.cloudProvider}
                  onChange={event => this.handleNewFormChange(event, 'cloudProvider')}
                  inputProps={{
                    name: 'cloudProvider',
                    id: 'server-cloudProvider'
                  }}
                  fullWidth
                  menuStyle={{
                    position: 'absolute',
                    top: -12.5
                  }}
                  underlineStyle={{
                    position: 'relative',
                    top: 20
                  }}
                >
                  <MenuItem
                    key='gcp'
                    value='gcp'
                    selected='yes'
                  >
                    gcp
                  </MenuItem>
                  <MenuItem
                    key='do'
                    value='do'
                  >
                    digital ocean
                  </MenuItem>
                </Select>
              </FormControl>
              {this.state.newServer.cloudProvider === 'gcp' ? (
                <TextField
                  style={{ marginBottom: 12 }}
                  margin='dense'
                  value={this.state.newServer.instanceName}
                  onChange={event => this.handleNewFormChange(event, 'instanceName')}
                  id='instanceName'
                  label='Host Gcp instance name'
                  type='text'
                  fullWidth
                />
              ) : (
                ''
              )}
              {this.state.newServer.cloudProvider === 'gcp' ? (
                <TextField
                  style={{ marginBottom: 12 }}
                  margin='dense'
                  value={this.state.newServer.zone}
                  onChange={event => this.handleNewFormChange(event, 'zone')}
                  id='zone'
                  label='Host Gcp instance zone'
                  type='text'
                  fullWidth
                />
              ) : (
                ''
              )}
              <FormControl
                style={{ marginBottom: 12 }}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor='type'>Type</InputLabel>
                <Select
                  value={this.state.newServer.type}
                  onChange={event => this.handleNewFormChange(event, 'type')}
                  inputProps={{
                    name: 'type',
                    id: 'server-type'
                  }}
                  fullWidth
                  menuStyle={{
                    position: 'absolute',
                    top: -12.5
                  }}
                  underlineStyle={{
                    position: 'relative',
                    top: 20
                  }}
                >
                  {this.props.serverTypes.map(type => (
                    <MenuItem
                      key={type}
                      value={type}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                style={{ marginBottom: 12 }}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor='type'>Will Hercules Be Installed On This Server?</InputLabel>
                <Select
                  value={this.state.newServer.hasHercules}
                  onChange={event => this.handleNewFormChange(event, 'hasHercules')}
                  inputProps={{
                    name: 'hasHercules',
                    id: 'server-hasHercules'
                  }}
                  fullWidth
                  menuStyle={{
                    position: 'absolute',
                    top: -12.5
                  }}
                  underlineStyle={{
                    position: 'relative',
                    top: 20
                  }}
                >
                  <MenuItem
                    key='false'
                    value='false'
                    selected='yes'
                  >
                    False
                  </MenuItem>
                  <MenuItem
                    key='true'
                    value='true'
                  >
                    True
                  </MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color='secondary'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.handleClose()
                this.props.addServer(this.state.newServer)
              }}
              style={{ color: 'black' }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

Servers.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(viewStyles)(Servers)
